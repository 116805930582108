import { defineStore } from 'pinia';
import { useFetchClient, useAuthUser } from '#imports';
import { useDiagnosisStore } from '~/stores/diagnosisStore';
import { useOkrsStore } from '~/stores/okrsStore';
export const useDefaultStore = defineStore('defaultStore', {
  state: () => ({
    data: [],
  }),
  getters: {},
  actions: {
    async init() {
      const diagnosisStore = useDiagnosisStore();
      const okrsStore = useOkrsStore();

      const user = useAuthUser().value;

      if (user?.okrRole !== null && user?.okrRole !== undefined) {
        const [{ data: campaigns }, { data: plans }] = await Promise.all([
          useFetchClient('/api/campaigns'),
          useFetchClient('/api/okrs/plans'),
        ]);

        diagnosisStore.campaigns = campaigns.value;
        okrsStore.plans = plans.value;
      } else {
        const { data: campaigns } = await useFetchClient('/api/campaigns');

        diagnosisStore.campaigns = campaigns.value;
      }
    },
  },
});
